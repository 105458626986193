import { FC, useMemo } from 'react';
import { Button, Card } from 'flowbite-react';
import { HiCheck, HiOutlineX } from 'react-icons/hi';
import { AsYouType } from 'libphonenumber-js';
import Countdown, { zeroPad } from 'react-countdown';

interface Props {
  data: any[];
  onCancel: (id: string) => void;
  onConfirm: (id: string) => void;
}

export const VirtualNumberActivationsList: FC<Props> = ({
  data,
  onCancel,
  onConfirm,
}: Props) => {
  const formattedActivations = useMemo(
    () =>
      data.map((activation) => {
        const { phoneNumber, smsCode, activationId, activationStatus } =
          activation;
        const formattedNumber = new AsYouType().input(`+${phoneNumber}`);
        const formattedSmsCode = smsCode
          ? Array.isArray(smsCode)
            ? smsCode[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            : smsCode.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          : '--- ---';

        return {
          formattedNumber,
          smsCode,
          formattedSmsCode,
          activationId,
          activationStatus,
          countdownTime:
            new Date(activation.activationTime).getTime() + 20 * 60 * 1000,
          x: 18 * 60 * 1000,
        };
      }),
    [data],
  );

  return data && data.length > 0 ? (
    <div className="mb-4">
      <div className="grid grid-cols-3 w-auto gap-4">
        {formattedActivations.map((activation, index) => (
          <Countdown
            key={activation.activationId}
            date={activation.countdownTime}
            renderer={({ minutes, seconds, completed, total }) => (
              <Card className={completed ? 'bg-red-100' : ''}>
                <div className="flex items-center gap-3 justify-between">
                  <div>{activation.formattedNumber}</div>
                  <div>{activation.formattedSmsCode}</div>
                  <div>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                  </div>
                  {activation.activationStatus === '2' ? (
                    <Button
                      size="xs"
                      color="blue"
                      onClick={() => onConfirm(activation.activationId)}
                    >
                      <HiCheck />
                    </Button>
                  ) : (
                    <Button
                      size="xs"
                      color="red"
                      onClick={() => onCancel(activation.activationId)}
                      disabled={total > activation.x}
                    >
                      <HiOutlineX />
                    </Button>
                  )}
                </div>
              </Card>
            )}
          />
        ))}
      </div>
    </div>
  ) : null;
};
