import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IAuth, ICreateUser } from '../types';

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL;

const getToken = (): string | null => {
  try {
    const token = localStorage.getItem('token');

    return token ? JSON.parse(token).token : null;
  } catch (error) {
    return null;
  }
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  },
);

export const auth = (payload: IAuth): Promise<AxiosResponse> =>
  axiosInstance.post('/login', payload);

export const getDevices = (params?: any): Promise<AxiosInstance> =>
  axiosInstance.get('/device', {
    params,
  });

export const getDeviceById = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/device/${id}`);

export const saveDevice = (payload: {
  [key: string]: any;
}): Promise<AxiosInstance> => axiosInstance.post(`/device`, payload);

export const updateDevice = (
  id: string,
  payload: { [key: string]: any },
): Promise<AxiosInstance> => axiosInstance.put(`/device/${id}`, payload);

export const updateAllDevices = (payload: {
  [key: string]: any;
}): Promise<AxiosInstance> => axiosInstance.post('/device/update-all', payload);

export const deleteDevice = (id: string): Promise<AxiosInstance> =>
  axiosInstance.delete(`/device/${id}`);

export const sendMessage = (id: string, payload: any): Promise<AxiosInstance> =>
  axiosInstance.post(`/device/sendmessage/${id}`, payload);

export const resetDevice = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/device/reset/${id}`);

export const resetMultipleDevice = (payload: any): Promise<AxiosInstance> =>
  axiosInstance.post('/device/reset', payload);

export const getDeviceLogsById = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/device/${id}/logs`);

export const getDeviceMessagesById = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/device/${id}/messages`);

export const getQuickStatistics = (): Promise<AxiosInstance> =>
  axiosInstance.get('/statistics/quick');

export const getStatistics = (params: {
  period: string;
}): Promise<AxiosInstance> => axiosInstance.get('/statistics', { params });

export const getUsers = (): Promise<AxiosInstance> =>
  axiosInstance.get('/user');

export const getUserById = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/user/${id}`);

export const saveUser = (payload: ICreateUser): Promise<AxiosInstance> =>
  axiosInstance.post('/user', payload);

export const updateUser = (id: string, payload: any): Promise<AxiosInstance> =>
  axiosInstance.put(`/user/${id}`, payload);

export const deleteUser = (id: string): Promise<AxiosInstance> =>
  axiosInstance.delete(`/user/${id}`);

export const getMessageTemplates = (): Promise<AxiosInstance> =>
  axiosInstance.get('/message-template');

export const addMessageTemplate = (payload: {
  [key: string]: any;
}): Promise<AxiosInstance> => axiosInstance.post('/message-template', payload);

export const getMessageTemplateById = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/message-template/${id}`);

export const deleteMessageTemplate = (id: string): Promise<AxiosInstance> =>
  axiosInstance.delete(`/message-template/${id}`);

export const updateMessageTemplate = (
  id: string,
  payload: { [key: string]: any },
): Promise<AxiosInstance> =>
  axiosInstance.put(`/message-template/${id}`, payload);

export const getSites = (): Promise<AxiosInstance> =>
  axiosInstance.get('/site');

export const addSite = (payload: {
  [key: string]: any;
}): Promise<AxiosInstance> => axiosInstance.post('/site', payload);

export const getSiteById = (id: string): Promise<AxiosInstance> =>
  axiosInstance.get(`/site/${id}`);

export const deleteSite = (id: string): Promise<AxiosInstance> =>
  axiosInstance.delete(`/site/${id}`);

export const updateSite = (
  id: string,
  payload: { [key: string]: any },
): Promise<AxiosInstance> => axiosInstance.put(`/site/${id}`, payload);

export const getFilterProxyList = (): Promise<AxiosInstance> =>
  axiosInstance.get('/filter/proxy-list');

export const getNumbers = (params: {
  period?: string;
  sortBy?: string;
  number?: string;
  numberType?: string;
  sortOrder: 'ASC' | 'DESC';
}): Promise<AxiosInstance> => axiosInstance.get('/numbers', { params });

export const getNumberStatistics = (params: {
  period?: string;
}): Promise<AxiosInstance> =>
  axiosInstance.get('/numbers/statistics', { params });

export const getVirtualNumberCountries = (params: {
  verification?: boolean;
}): Promise<AxiosInstance> =>
  axiosInstance.get('/virtual-number/countries', { params });

export const getVirtualNumber = (payload: {
  country: number;
}): Promise<AxiosInstance> =>
  axiosInstance.post('/virtual-number/numbers', payload);

export const getVirtualNumberActivations = (): Promise<AxiosInstance> => {
  return axiosInstance.get('/virtual-number/activations');
};

export const getFavoriteCountries = (): Promise<AxiosInstance> => {
  return axiosInstance.get('/virtual-number/favorites');
};

export const selectFavoriteCountry = (
  countryId: number,
): Promise<AxiosInstance> => {
  return axiosInstance.post('/virtual-number/favorites', {
    countryId,
  });
};

export const removeFavoriteCountry = (
  countryId: number,
): Promise<AxiosInstance> => {
  return axiosInstance.delete('/virtual-number/favorites', {
    data: {
      countryId,
    },
  });
};

export const updateStatusVirtualNumber = (
  id: string,
  status: number,
): Promise<AxiosInstance> =>
  axiosInstance.get(`/virtual-number/numbers/${id}`, {
    params: {
      status,
    },
  });

export const virtualNumberBalance = (): Promise<AxiosInstance> =>
  axiosInstance.get(`/virtual-number/balance`);

export const getQueueSchedule = (): Promise<AxiosInstance> =>
  axiosInstance.get('/device/queue-schedule');

export const updateQueueSchedule = (payload: {
  enabled: boolean;
}): Promise<AxiosInstance> =>
  axiosInstance.put('/device/queue-schedule', payload);
